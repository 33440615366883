import React, { useState } from "react"
import { graphql } from 'gatsby'
import Nav from "../components/UI/Nav/Nav"
import { Container, Row } from "react-bootstrap"
import SectionsListItems from "../components/UI/SectionsListItems/SectionsListItems"
import HeadImage from "../components/UI/HeadImage/HeadImage"
import Modal from "../components/UI/Modal/Modal"
import Footer from "../components/UI/Footer/Footer"
import SEO from "../components/seo"

const ServicesPage = ({ data }) => {
  const [modalShow, setModalShow] = useState(false)
  const servicesSections = []
  const images = [
    data.services1.childImageSharp.fluid.src,
    data.services2.childImageSharp.fluid.src,
  ]
  data.prismic.allServicioss.edges.map(items => {
    if (items.node.categoria in servicesSections)
      servicesSections[items.node.categoria].push({
        title: items.node.nombre_de_servicio[0].text,
        description: items.node.descripcion.map(item => item.text),
      })
    else
      servicesSections[items.node.categoria] = [
        {
          title: items.node.nombre_de_servicio[0].text,
          description: items.node.descripcion.map(item => item.text),
        },
      ]
    return null
  })
  return (
    <Container fluid>
      <SEO />
      <Modal show={modalShow} handleClose={() => setModalShow(false)} />
      <Nav active="SERVICIOS" />
      <Row>
        <HeadImage
          image={data.servicesheader.childImageSharp.fluid}
          height="auto"
        >
          <p className="small-size">
            Contamos con permiso de Asesor Especializado en Seguridad
            Radiológica(AESR) para establecimientos de diagnóstico médico con
            rayos x <span className="active">No. 006/AESR/2018</span> ante{" "}
            <span className="active">
              COFEPRIS y AUTORIZACION STPS VALD861019LV70005.
            </span>
          </p>
        </HeadImage>
      </Row>
      <Row>
        <SectionsListItems
          sections={servicesSections}
          images={images}
          showModal={() => setModalShow(true)}
        />
      </Row>
      <Footer />
    </Container>
  )
}

export default ServicesPage

export const pageQuery = graphql`
  query servicesQuery {
    prismic {
      allServicioss {
        edges {
          node {
            categoria
            nombre_de_servicio
            descripcion
            _linkType
          }
        }
      }
    }
    servicesheader: file(relativePath: { eq: "index.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    services1: file(relativePath: { eq: "services-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    services2: file(relativePath: { eq: "services-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
